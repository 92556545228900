/* This file Contains Constant of Routes which have Different Mapping on BackEnd.
Ex -> Rejection is mapped to mismatchRejections/transactionRejection , Backend is ginving Rejection in API
and Our Route is /mismatchRejections/transactionRejection .
Add Your Route in Case if The mapping is Different on Backend
*/
export const LEFT_SIDEBAR_TAB_OPTION_ADMIN = {
	'nseBseMfuSchemes': { url: "systemData/completeSchemeDetails" },
	'soaDownload': { url: "dataManagement/SOADownload" },
	'api': { url: "createCredentials/apiCredential" },
	'subUser': { url: "createCredentials/subUserCredential" },
	'rejection': { url: "mismatchRejections/transactionRejection" },
};

export const getRedirectFeature = (redirectFeature) => {
    if(redirectFeature){
      const parentFeature =  redirectFeature.feature
      const subFeature = redirectFeature.subFeature && redirectFeature.subFeature[0]
      const redirectUrl = subFeature ? `${parentFeature}/${subFeature}` : `${parentFeature}`
      const customFeature = subFeature || parentFeature
      const customUrl = LEFT_SIDEBAR_TAB_OPTION_ADMIN.hasOwnProperty(customFeature) ? LEFT_SIDEBAR_TAB_OPTION_ADMIN[customFeature]['url'] : redirectUrl
      return customUrl
    }
   }