import React from 'react'
import routes from 'app/routes'
import Routes from './routes';
import ProgressBar from 'app/uiCollection/shared/loaders/progressBar'
import { BrowserRouter as Router,hashHistory, Route, Link } from "react-router-dom";
/*import { Router, hashHistory } from 'react-router-dom'*/
import { HashRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReCaptcha from 'app/uiCollection/shared/recaptcha'
import { dependencyCheck, maskUserData } from 'app/utils/dataFormater'
import { CheckMaintainance } from 'app/actions/user'
import {CHECK_FOR_MAINTAINANCE} from 'app/constants/index'
class App extends React.Component{
	constructor(props){
		super(props);

		this.state ={
			stepsEnabled: false,
			initialStep: 0,
			steps :[],
			options :{
			showBullets :false,
			showProgress:true,
			showTourGuide:true
			}
		}

	}
	UNSAFE_componentWillReceiveProps(newProps){
		this.setState({
			showTourGuide:true
		})
		let loginTabTitle = ''
		let startTourSession = document.getElementsByClassName("playTour")[0];
		let crossBtns = document.getElementsByClassName("cross-btn-tltip")[0];
		if(startTourSession){
			startTourSession.addEventListener('click', () =>{
				crossBtns.click();
				setTimeout(() => {
					this.onStart();
				}, 7000);
			} )
		}
		
		let pageUrl= window.location.href.split('#')[1].split("/");
		let pageName = pageUrl[pageUrl.length-1] ;
		let userType = pageUrl[pageUrl.length-2] ;
		// cookies set securely
		const isCookieSecure =  process.env.IS_COOKIE_SECURE == 'true' ? 'Secure; SameSite=Strict;' : ''
		document.cookie = `${pageName}; path=/app; ${isCookieSecure}`;
		function getCookie(name) {
		    let checkCookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
		    return checkCookie ? checkCookie[2] : null;
		}
		let existing = getCookie('localCookie')

		existing = existing ? existing.split(',') : [];
		for (let i=0 ; i < existing.length ;i++){
			if( pageName === existing[i]){
				this.setState({
					showTourGuide:false
				})
			break
			}
		}
		existing.push(pageName);
		
		function setCookie(name, value, days) {
		    let dateStamp = new Date;
		    dateStamp.setTime(dateStamp.getTime() + 24*60*60*1000*days);
		    document.cookie = name + "=" + value + ";path=/;expires=" + dateStamp.toGMTString();
		}
		
		// setCookie('localCookie', existing, 365) // uncommnet this function while we will goining to use tour guide.
		if(newProps.firstLoginInfo && newProps.firstLoginInfo && newProps.firstLoginInfo.user && newProps.firstLoginInfo.user.userType=='admin'){
			let setSelectedTab = newProps.firstLoginInfo && newProps.firstLoginInfo.setSelectedTab || {}
			if(setSelectedTab && (setSelectedTab.parentTab && setSelectedTab.childTab)){
				loginTabTitle = (setSelectedTab.childTab.label)
			}else{
				loginTabTitle = (setSelectedTab.parentTab && setSelectedTab.parentTab.label)
			}			
	   }
		else {
			if (newProps.firstLoginInfo && newProps.firstLoginInfo.user && Object.keys(newProps.firstLoginInfo.user).length) {
				loginTabTitle = newProps.firstLoginInfo.user.name
				if (dependencyCheck(newProps.firstLoginInfo, 'getUserInfo.data.name')) {
					loginTabTitle = maskUserData(newProps.firstLoginInfo.getUserInfo.data.name,'name',newProps.maskDataFlag)
				}
			} else {
				loginTabTitle = dependencyCheck(newProps.firstLoginInfo, 'theme.compName')
			}
		}
	   document.title =  ( loginTabTitle  || '')

		switch( this.state.showTourGuide  && ( userType =='broker') && pageName )
		{
			case 'dashboard' :
			setTimeout(() => {
				this.onStart();
			}, 3000);
			this.setState({
				steps: [
					{
						element: '.graphChartArea',
						intro: '<div class="tourHeading">This is your IFA Dashboard</div> You can get a quick overview of your Business metrics like AUM, No. of Investor and their SIP details.',
					},
					{
						element: '.chartBox',
						intro: '<div class="tourHeading">Net Sales graph</div> This chart shows you the change in your AUM per day. Net Sales = Investments - Redemptions.',
					},
					{
						element: '.clientMetrics',
						intro: '<div class="tourHeading">Client Metrics </div> The most recently created clients and a list of Top clients based on AUM.',
					},
					{
						element: '.latestTxns',
						intro: '<div class="tourHeading">Latest Transactions</div> These are the latest transaction done by your Investors. Transactions types included are Purchase, Redemption, SIP, STP, Div Payout, Bonus.',
					},
				]
			})
			break;
			
			case 'investorLookup':
			setTimeout(() => {
				this.onStart();
			}, 3000);

			this.setState({
				steps: [
					{
						element: '.searchBox',
						intro: 'Use this to Search for any Family Head.',
				  	},
					{
						element: '.customize',
						intro: '<div class="tourHeading">Unassigned RM filter </div> Use this to view Families with no RM.',
					},
					{
						element: '.switchBox',
						intro: 'Use this to change the table listing and search to show Investors. <br> 1.Investor action - Set Family Head <br /> 2.Investor action - Set RM',
					}
				]
			})
			break;

			case 'folioLookup':
			setTimeout(() => {
				this.onStart();
			}, 3000);

			this.setState({
				steps: [
					{
						element: '.searchBox',
						intro: 'Use this to Search for Folio/Investor.',
					},
					{
						element: '.switchBox',
						intro: 'Use this to search for Investor.',
					},  
					{
						element: '.folioOpts .switchBox',
						intro: 'View active or all folios.',
					},  
					{
						element: '.upDown',
						intro: ' Sort data accordintg to requirement.',
					},  
					{
						element: 'table tbody tr:first-child .soaIcon',
						intro: 'View SOA',
					},  
					{
						element: 'table tbody tr:first-child .folioNum',
						intro: ' View Folio details',
					},  
					{
						element: '.customCheckBox',
						intro: ' Demerge to new or existing investor',
					}
				]
			})
			break;

			case 'transactions':
			setTimeout(() => {
				this.onStart();
			}, 3000);

			this.setState({
				steps: [
					{
						element: '.calendar-box',
						intro: '1 week default time period.',
					},
					{
						element: '.txnFilter',
						intro: 'Transaction type filter.',
					},  
					{
						element: '.user-filter',
						intro: 'User filter.',
					}
				]
			})
			break;
			case 'topschemes':
			setTimeout(() => {
				this.onStart();
			}, 3000);

			this.setState({
				steps: [
					{
						element: '.head-label',
						intro: "Make well informed investment decisions by looking at Returns and Ratios of India's Top Mutual Fund Schemes.",
					},
					{
						element: '.searchBox',
						intro: 'Search scheme.',
					},  
					{
						element: '.txnFilter',
						intro: 'Filters - Fund, Category, Scheme type',
					},  
					{
						element: '.toggleRatio .switchBox',
						intro: 'View trailing returns or ratios',
					},  
					{
						element: '.toggleAbsolute .switchBox',
						intro: 'Absolute or Annualized returns',
					},  
					{
						element: '.customizeBtns',
						intro: '<div class="tourHeading">Add month columns </div> Dive Deeper into Top Schemes. You can view Returns on Add more columns - Days returns',
					},  
					{
						element: 'table tbody tr:first-child .linkInTable',
						intro: ' Factsheet',
					}
				]
			})
			break;

			default : 
			this.setState({
				stepsEnabled: false
			})
		}
		if (!this.state.checkMaintainanceCalled && (newProps.firstLoginInfo && newProps.firstLoginInfo.checkForMaintainance)) {
			this.props.dispatch(CheckMaintainance())
			this.setState({ checkMaintainanceCalled: true })
			this.props.dispatch({ type: "CHECK_FOR_MAINTAINANCE", payload: false });
		}
		if (dependencyCheck(newProps.firstLoginInfo, 'maintainanceResponse.response.status') && (CHECK_FOR_MAINTAINANCE.includes(newProps.firstLoginInfo.maintainanceResponse.response.status))) {
			window.location.href = '/app/error-pages/systemMaintainance.html'
		}
	}

	updateNetworkStatus = () => {
		if (process.env.NODE_ENV == 'production') {
			alert("You are offline! Please check your internet connection.")
			location.reload()
		}
	}
	componentDidMount() {
		window.addEventListener("offline", this.updateNetworkStatus)
	}

	componentWillUnmount(){
		window.removeEventListener("offline", this.updateNetworkStatus)
		this.setState({
			stepsEnabled: false
		})
	}
	onExit ()
	{
		this.setState({
		  stepsEnabled: false
		})
	}
	onStart ()
	  {
	  	return //remove return when guide will be enable
		this.setState({
		  stepsEnabled: true
		})
	  }
	//<ProgressBar pageProgress={this.props.pageProgress}/>
	render(){
		return(
			<div>
		    	{/*<Router history={hashHistory}  routes={Routes} />*/}
		    	 <HashRouter>
		    	    <Routes/>
		    	 </HashRouter>
				{/*<Steps
					enabled={this.state.stepsEnabled}
					steps={this.state.steps}
					initialStep={this.state.initialStep}
					options={this.state.options}
					onExit={() => this.onExit()}
				></Steps>*/}
				{this.props.showRecaptchaPopup == 1 && 
				<div id='wrapperArea' class="popUpInfoArea">
					<ReCaptcha />
				 </div>
				}
		  </div>
		)
	}
}
const mapStateToProp = (state) =>{
	return({
		'pageProgress':state.config.pageProgress||{},
		firstLoginInfo : state.userReducer,
		showRecaptchaPopup: state.userReducer.showRecaptchaPopup,
		maskDataFlag: state.Login && state.Login.maskClientData

	})
}
const mapDispatchToProps = (dispatch)=>{
  return{
    dispatch:dispatch
  }
}
export default connect(mapStateToProp,mapDispatchToProps)(App)


