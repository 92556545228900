var intialState = {
  newInvestment: {},
  fundList: {},
  IINList: {},
  additionalInvestment: {},
  factSheetDetails: {},
  saveItemInCart: {},
  getCartItems: {},
  factSheetDetails: {},
  factSheetGraph: {},
  schemeType: {},
  dateAndFrequencies: {},
  targetScheme: {},
  balanceUnits: {},
  myOrderList: {},
  mfuBanks: {},mfuMandateList: {}
};

export default function investOnline(state = intialState, action) {
  switch (action.type) {

    case 'GOT_NEW_INVESTMENT_DATA':
      return { ...state, newInvestment: action.payload }
    case 'GOT_INVESTMENT_DATA_ISIN' :
      return { ...state, newInvestmentIsin: action.payload }
    case 'COMPONENT_LOADER_NEW_INVESTMENT':
      return {...state,loaderNewInvestment:action.payload}
    case 'FAILED_NEW_INVESTMENT_DATA':
      return { ...state, newInvestment: { errorMsg: action.payload } }

    case 'GOT_FUNDS_DATA':
      return { ...state, fundList: action.payload }
    case 'COMPONENT_LOADER_GET_FUNDS':
      return {...state,loaderFunds:action.payload}
    case 'FAILED_FUNDS_DATA':
      return { ...state, fundList: { errorMsg: action.payload } }

    case 'GETTING_IIN_LIST_DATA':
      return { ...state,gettingIINList:true,gotIINList:false}
    case 'GOT_IIN_LIST_DATA':
      return { ...state, IINList: action.payload,gettingIINList:false,gotIINList:true }
    case 'COMPONENT_LOADER_GET_IIN':
      return{...state,loaderPopUp:action.payload}
    case 'FAILED_IIN_LIST_DATA':
      return { ...state, IINList: { errorMsg: action.payload } }

    case 'GOT_ADDITIONAL_INVESTMENT_DATA':
      return { ...state, additionalInvestment: action.payload }
    case 'COMPONENT_LOADER_ADDITIONAL_INVESTMENT':
      return {...state,loaderAdditionalInvestment:action.payload}
    case 'FAILED_ADDITIONAL_INVESTMENT_DATA':
      return { ...state, additionalInvestment: { errorMsg: action.payload } }

    case 'GOT_FACTSHEET_DATA':
      return { ...state, factSheetDetails: action.payload }
    case 'COMPONENT_LOADER_FACT_SHEET':
      return{...state,loaderPopUp:action.payload}
    case 'FAILED_FACTSHEET_DATA':
      return { ...state, factSheetDetails: { errorMsg: action.payload } }

    case 'GETTING_INSERT_IN_CART_DATA':
      return { ...state, saveItemInCart: { data: action.payload, apiStatus: 'pendding' } }
    case 'GOT_INSERT_IN_CART_DATA':
      return { ...state, saveItemInCart: { data: action.payload, apiStatus: true }, editCartResponse : action.completeResponse }
    case 'FAILED_INSERT_IN_CART_DATA':
      return { ...state, saveItemInCart: { errorMsg: action.payload, apiStatus: false } , editCartResponse : action.completeResponse }

    case 'GOT_CART_ITEMS_DATA':
      return { ...state, getCartItems: action.payload }
    case 'FAILED_CART_ITEMS_DATA':
      return { ...state, getCartItems: { errorMsg: action.payload } }

    case 'GOT_FACTSHEET_DATA':
      return { ...state, factSheetDetails: { result: action.payload, data: action.payload.data } }

    case 'FAILED_FACTSHEET_DATA':
      return { ...state, factSheetDetails: { errorMsg: action.payload } }


    case 'GOT_FACTSHEET_GRAPH_DATA':
      return { ...state, factSheetGraph: { result: action.payload, data: action.payload.data } }
     case 'COMPONENT_LOADER_FACT_GRAPH_SHEET':
      return{...state,loaderPopUp:action.payload}
    case 'FAILED_FACTSHEET_GRAPH_DATA':
      return { ...state, factSheetGraph: { errorMsg: action.payload } }

    case 'GETTING_SCHEME_TYPE_DATA':
      return { ...state, schemeType: { data: action.payload, apiStatus: 'pendding' } }
    case 'GOT_SCHEME_TYPE_DATA':
      return { ...state, schemeType: { result: action.payload, data: action.payload.data, apiStatus: true }}
    case 'COMPONENT_LOADER_SCHEME_TYPE':
      return{...state,loaderPopUp:action.payload}
    case 'FAILED_SCHEME_TYPE_DATA':
      return { ...state, schemeType: { errorMsg: action.payload, apiStatus: false }}

    case 'GOT_SOURCE_SCHEME_TYPE_DATA':
    case 'FAILED_SOURCE_SCHEME_TYPE_DATA':
      return {...state, sourceScheme: action.payload }

    case 'GOT_DATE_FREQUENCIES_DATA':
      return { ...state, dateAndFrequencies: { result: action.payload, data: action.payload.data, apiStatus: true } }
    case 'FAILED_DATE_FREQUENCIES_DATA':
      return { ...state, dateAndFrequencies: { errorMsg: action.payload, apiStatus: false } }

    case 'GOT_TARGET_SCHEME_DATA':
      return { ...state, targetScheme: { result: action.payload, data: action.payload.data, apiStatus: true } }
    case 'COMPONENT_LOADER_MY_ORDERS_TARGET':
      return{...state,loaderPopUp:action.payload}
    case 'FAILED_TARGET_SCHEME_DATA':
      return { ...state, targetScheme: { errorMsg: action.payload, apiStatus: false } }

    case 'GOT_BALANCE_UNITS_DATA':
      return { ...state, balanceUnits: { result: action.payload, data: action.payload&&action.payload.data, apiStatus: true } }
    case 'FAILED_BALANCE_UNITS_DATA':
      return { ...state, balanceUnits: { errorMsg: action.payload, apiStatus: false } }

    case 'GETTING_SCHEME_NSE_INFO':
      return {...state, gettingSchemeNseInfo:true,gotSchemeNseInfo:false}
    case 'GOT_SCHEME_NSE_INFO':
      return { ...state,nseSchemeInfo:action.payload, schemeList:action.payload && action.payload.allowedInvestmentTypes, gettingSchemeNseInfo:false,gotSchemeNseInfo:true}
    case 'FAILED_SCHEME_NSE_INFO':
      return { ...state, schemeListError:action.payload, gettingSchemeNseInfo:false,failedSchemeNseInfo:true}

    case 'GOT_MY_ORDERS_DATA':
      return { ...state, myOrderList: { result: action.payload, data: action.payload.data, apiStatus: true } }
    case 'COMPONENT_LOADER_MY_ORDERS':
      return {...state,loaderMyOrder:action.payload}
    case 'FAILED_MY_ORDERS_DATA':
      return { ...state, myOrderList: { errorMsg: action.payload, apiStatus: false } }
      
    case 'PLACE_ORDER':
      return { ...state, placingOrder: true, orderPlaced: false }
    case 'ORDER_PLACED':
      return { ...state, placingOrder: false, orderPlaced: true }
    /*case 'ORDER_PLACED':
      return { ...state, placingOrder: false, orderPlaced: true }*/

    case 'GOT_SCHEME_DETAILS':
      return { ...state, schemeDetail: action.payload.allowedInvestmentTypes }
    case 'FAILED_SCHEME_DETAILS':
      return { ...state, schemeDetailError: action.payload }
    case 'GOT_SOURCE_SCHEME_DETAILS':
      return { ...state, sourceSchemeDetail: action.payload.allowedInvestmentTypes }
    case 'FAILED_SOURCE_SCHEME_DETAILS':
      return { ...state, sourceSchemeDetail: action.payload }
    case 'CLEAR_NOTIFICATION':
      return {...state,notificationData:action.payload}

    case 'GOT_IIN_LIST_BY_FOLIOS':
      return { ...state, IINListWithFolio: action.payload }
    case 'FAILED_IIN_LIST_BY_FOLIOS':
      return { ...state, IINListWithFolio: action.payload }

    case 'GOT_BUY_NOW_DATA':
      return { ...state, buyNowData: action.payload ,notificationData:action.payload}
    case 'COMPONENT_LOADER_BUY_NOW':
      return { ...state, loaderPopUp: action.payload }
    case 'FAILED_BUY_NOW_DATA':
      return { ...state, buyNowData: action.payload ,notificationData:action.payload}


    case 'GOT_CAN_LIST':
      return { ...state, canList: action.payload}
    case 'FAILED_TO_GET_CAN_LIST':
      return { ...state, canList: action.payload }
    case 'COMPONENT_LOADER_CAN_LIST':
      return { ...state, canListLoader: action.payload}

    case 'GOT_MFU_BANK_LIST':
      state.mfuBanks[action.canNo] = action.payload
      return { ...state, mfuBanks: state.mfuBanks}
    case 'FAILED_TO_GET_MFU_BANK_LIST':
      return { ...state, mfuBanks: action.payload }

    case 'SUCCESSFULLY_MFU_ORDER':
      return { ...state, mfuOrderInfo: action.payload, notificationData: action.payload.message, isError: false}
    case 'FAILED_MFU_ORDER':
      return { ...state, mfuOrderInfo: action.payload, notificationData: action.payload, isError: true }

    case 'GOT_MFU_MANDATE_LIST':
      state.mfuMandateList[action.canNo] = action.payload
      return { ...state, mfuMandateList: state.mfuMandateList }
    case 'FAILED_TO_GET_MFU_MANDATE_LIST':
      return { ...state, mfuMandateList: action.payload}

    case 'LOADER_MFU_ORDER':
      return {...state, mfuOrderLoader: action.payload}


    case 'GOT_NFO_SCHEMES_DATA':
      return { ...state,nfoSchemes: action.payload }
    case 'FAILED_NFO_SCHEMES_DATA':
      return { ...state,nfoSchemes: action.payload }

    case 'GOT_CLIENT_USER_DATA':
    case 'FAILED_CLIENT_USER_DATA':
      return { ...state,clientsUnderUser: action.payload }

    case 'UPDATE_CLIENT_USER':
    case 'FAILED_UPDATE_CLIENT_USER':
      return { ...state,updatedClientsList: action.payload }

    case 'GOT_CATEGORIES_DATA':
      return { ...state,categoriesData: action.payload }
    case 'FAILED_CATEGORIES_DATA':
      return { ...state, categoriesData: action.payload }

    case 'GOT_SUB_CATEGORIES_DATA':
      return { ...state,subCategoriesData: action.payload }
    case 'FAILED_SUB_CATEGORIES_DATA':
      return { ...state, subCategoriesData: action.payload }

    case 'BUYNOW_MODE_OPTIONS':
      return { ...state, buyNowModeOption: action.payload,}
     
    case 'INSERTED_MFU_ORDER_SUCCESSFULLY':
    case 'FAILED_TO_INSERT_MFU_ORDER' :
      return  { ...state,insertedMFUOrder: action.payload} 
      
    case 'GOT_MFU_CLIENT_CART_DATA':
    case 'FAILED_MFU_CLIENT_CART_DATA':
      return {...state,mfuClientCartData: action.payload}

    case 'PLACED_MFU_ORDER_SUCCESSFULLY':
    case 'FAILED_TO_PLACE_MFU_ORDER' :
      return {...state,placeMfuOrderCartStatus: action.payload}

    case 'ORDER_SUBMIT_PARAM_DETAILS':
        return { ...state, orderSubmitParamDetails: action.payload }

    case 'SHOW_HIDE_CUSTOM':
      return{...state,showHideCustomOpts:action.payload}

    default:
      new Error('No action type matched in reducer')
  }
  return state
}

