export default function investOnlineBse(state={},action){
	
	switch(action.type){

		case 'GOT_CREATE_PROFILE_DATA':
			return{...state,createProfileData:action.payload, notificationData:action.payload}
		case 'COMPONENT_LOADER_CREATE_PROFILE':
			return {...state,loaderCreateProfile:action.payload}
		case 'FAILED_CREATE_PROFILE_DATA':
			return{...state,createProfileData:action.payload, notificationData:action.payload}

		case 'CLEAR_NOTIFICATION':
			return{...state,notificationData:action.payload}

		case 'GOT_SEND_OTP_DATA':
			return{...state,sendOTPData:action.payload}
		case 'COMPONENT_LOADER_SEND_OTP_DATA':
			return {...state,loaderCreateProfile:action.payload}
		case 'FAILED_SEND_OTP_DATA':
			return{...state,sendOTPData:action.payload}

		case 'GOT_VERIFY_OTP_DATA':
			return{...state,validateOTPData:action.payload}
		case 'COMPONENT_LOADER_CREATE_PROFILE':
			return {...state,loaderCreateProfile:action.payload}
		case 'FAILED_VERIFY_OTP_DATA':
			return{...state,validateOTPData:action.payload}

		case 'GOT_CHECKED_KYC_DATA':
			return{...state,panStatus:action.payload && action.payload.data}
		case 'COMPONENT_LOADER_CHECK_KYC':
			return {...state,loaderPanStatus:action.payload}
		case 'FAILED_CHECKING_KYC':
			return{...state,panStatus:action.payload}

		case 'GOT_CITY_STATE_DATA':
			return{...state,cityAndState:action.payload}
		case 'COMPONENT_LOADER_CITY_LIST':
			return{...state,loaderInvestorRegistration:action.payload}
		case 'FAILED_GETTING_CITY_STATE':
			return{...state,cityAndState:action.payload}

		case 'GOT_INVESTOR_REGISTRATION_DATA':
			return{...state,registrationData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_INVESTOR_REGISTRATION':
			return {...state,loaderInvestorRegistration:action.payload}
		case 'FAILED_INVESTOR_REGISTRATION_DATA':
			return{...state,registrationData:action.payload, notificationData:action.payload}

		case 'GOT_NOMINEE_DETAILS_DATA':
			return{...state,nomineeDetailsData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_NOMINEE_DETAILS':
			return {...state,loaderNomineeDetails:action.payload}
		case 'FAILED_NOMINEE_DETAILS_DATA':
			return{...state,nomineeDetailsData:action.payload, notificationData:action.payload}

		case 'GOT_BANK_DETAILS_DATA':
			return{...state,bankDetailsData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_BANK_DETAILS':
			return {...state,loaderBankDetails:action.payload}
		case 'FAILED_BANK_DETAILS_DATA':
			return{...state,bankDetailsData:action.payload, notificationData:action.payload}

		case 'LOGIN_USER_TYPE':
			return{...state,userType:action.payload}

		case 'EDIT_BANK_DETAILS_DATA':
			return{...state,editBankDetails:action.payload}

		case 'BANK_DETAILS_DATA':
			return{...state,savedBankDetails:action.payload}

		case 'SET_AS_DEFAULT_BANK':
			return{...state,defaultBankData:action.payload}

		case 'DELETE_BANK_FROM_LIST':
			return{...state,deleteBankData:action.payload}

		case 'STEP_NO_BSE_FORM':
			return {...state,stepNo:action.payload}

		case 'STEP_NO_BSE_FORM_FLAG':
			return {...state,stepNoFlag:action.payload}

		case 'SAVE_AND_EXIT':
			return {...state,saveAndExit:action.payload}

		case 'INDEX_UPLOAD_DOC':
			return {...state,indexUploadDoc:action.payload}

		case 'ON_PAN_CHANGE_FLAG':
			return {...state,CheckKYCFlag:action.payload}

		case 'EDIT_FATCA_DETAILS_DATA':
			return {...state,editFatcaDetails:action.payload}

		case 'FATCA_DETAILS_FINISH_FLAG':
			return {...state,fatcaDetailsFinish:action.payload}

		case 'CREATE_PROFILE_SUBMIT_OBJECT':
			return {...state,createProfileSubmitObj:action.payload}

		case 'SHOW_HIDE_NOTIFICATION_MSG':
			return {...state,showHideNotification:action.payload}

		case 'GOT_FATCA_DATA':
			return{...state,fatcaDetailsData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_FATCA':
			return {...state,loaderFatca:action.payload}
		case 'FAILED_FATCA_DATA':
			return{...state,fatcaDetailsData:action.payload, notificationData:action.payload}

		case 'GOT_EDIT_UCC_DATA':
			return{...state,editUserUccData:action.payload}
		case 'COMPONENT_LOADER_EDIT_UCC_DATA':
			return {...state,loaderEditUccData:action.payload}
		case 'FAILED_EDIT_UCC_DATA':
			return{...state,editUserUccData:action.payload}

		case 'GOT_BSE_COUNTRY_DATA':
			return{...state,bseCountryData:action.payload}
		case 'FAILED_BSE_COUNTRY_DATA':
			return{...state,bseCountryData:action.payload}

		case 'GOT_UCC_HOLDERS_DATA':
			return{...state,uccHoldersData:action.payload}
		case 'FAILED_UCC_HOLDERS_DATA':
			return{...state,uccHoldersData:action.payload}

		case 'GOT_STATE_CODE_DATA':
			return{...state,stateCodeData:action.payload}
		case 'FAILED_STATE_CODE_DATA':
			return{...state,stateCodeData:action.payload}
		
		case 'GOT_SUBMIT_DOCUMENT':
			return{...state,submitDocData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_SUBMIT_DOCUMENT':
			return {...state,loaderSubmitDoc:action.payload}
		case 'FAILED_SUBMIT_DOCUMENT':
			return{...state,submitDocData:action.payload,notificationData:action.payload}

		case 'SUCCESS_SUBMIT_CHEQUE_TO_BSE':
				return{...state,submitChequeData:action.payload,notificationData:action.payload}	
		case 'SUBMIT_CHEQUE_TO_BSE_LOADER':
				return {...state,loaderSubmitCheque:action.payload}
		case 'FAILED_SUBMIT_CHEQUE_TO_BSE':
				return{...state,submitChequeData:action.payload,notificationData:action.payload}

		case 'GOT_BSE_PREFERENCES_DATA':
			return{...state,bsePreferencesData:action.payload}
		case 'COMPONENT_LOADER_BSE_PREFERENCES':
			return {...state,loaderGetPreferences:action.payload}
		case 'FAILED_BSE_PREFERENCES_DATA':
			return{...state,bsePreferencesData:action.payload}

		case 'BSE_SET_PREFERENCES_DATA':
			return{...state,bseSetPreferencesData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_BSE_SET_PREFERENCES':
			return {...state,loaderSetPreferences:action.payload}
		case 'FAILED_BSE_SET_PREFERENCES_DATA':
			return{...state,bseSetPreferencesData:action.payload,notificationData:action.payload}

		case 'BSE_SET_PREFERENCES_FLAG':
			return{...state,bseSetPreferencesFlag:action.payload}

		case 'GOT_UCC_LIST_DATA':
			return{...state,uccListData:action.payload}
		case 'COMPONENT_LOADER_UCC_LIST':
			return {...state,loaderUccList:action.payload}
		case 'FAILED_UCC_LIST_DATA':
			return{...state,uccListData:action.payload}

		case 'GOT_MANDATE_LIST':
			return{...state,mandateListData:action.payload}
		case 'COMPONENT_LOADER_MANDATE_LIST':
			return {...state,loaderMandateList:action.payload}
		case 'FAILED_MANDATE_LIST':
			return{...state,mandateListData:action.payload}

		case 'GOT_BANK_LIST_DATA':
			return{...state,mandateBankListData:action.payload}
		case 'COMPONENT_LOADER_BANK_LIST':
			return {...state,loaderMandateBankList:action.payload}
		case 'FAILED_BANK_LIST_DATA':
			return{...state,mandateBankListData:action.payload}

		case 'GOT_REGISTER_MANDATE_DATA':
			return{...state,regiterMandateData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_REGISTER_MANDATE':
			return {...state,loaderRegisterMandate:action.payload}
		case 'FAILED_REGISTER_MANDATE_DATA':
			return{...state,regiterMandateData:action.payload,notificationData:action.payload}

		case 'GOT_UCC_LIST_SUMMARY_DATA':
			return{...state,uccListSummaryData:action.payload}
		case 'COMPONENT_LOADER_UCC_LIST_SUMMARY':
			return {...state,loaderUccListSummary:action.payload}
		case 'FAILED_UCC_LIST_SUMMARY_DATA':
			return{...state,uccListSummaryData:action.payload}

		case 'GOT_BULK_UPLOAD_MANDATES_DATA':
			return{...state,bulkUploadMandates:action.payload, notificationData:action.payload}
		case 'COMPONENT_LOADER_BULK_UPLOAD_MANDATES':
			return {...state,loaderBulkUpload:action.payload}
		case 'FAILED_BULK_UPLOAD_MANDATES_DATA':
			return{...state,bulkUploadMandates:action.payload, notificationData:action.payload}

		case 'GOT_BULK_UPLOAD_UCC_DATA':
			return{...state,bulkUploadUccData:action.payload}
		case 'COMPONENT_LOADER_BULK_UPLOAD_UCC':
			return {...state,loaderBulkUpload:action.payload}
		case 'FAILED_BULK_UPLOAD_UCC_DATA':
			return{...state,bulkUploadUccData:action.payload}

		case 'GOT_MANDATES_LIST_DATA':
			return{...state,mandateListData:action.payload}
		case 'COMPONENT_LOADER_MANDATES_LIST':
			return {...state,loaderMandateList:action.payload}
		case 'FAILED_MANDATES_LIST_DATA':
			return{...state,mandateListData:action.payload}

		case 'GOT_DELETE_MANDATE_DATA':
			return{...state,deleteMandateData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_DELETE_MANDATE':
			return {...state,loaderDeleteMandate:action.payload}
		case 'FAILED_DELETE_MANDATE_DATA':
			return{...state,deleteMandateData:action.payload,notificationData:action.payload}

		case 'GOT_DELETE_BSE_PROFILE_DATA':
			return{...state,deleteBseProfileData:action.payload,notificationData:action.payload}
		case 'FAILED_DELETE_BSE_PROFILE_DATA':
			return{...state,deleteBseProfileData:action.payload,notificationData:action.payload}

		case 'GOT_DELETE_UCC_TEMP_DATA':
			return{...state,deleteUccData:action.payload,notificationData:action.payload}
		case 'FAILED_DELETE_UCC_TEMP_DATA':
			return{...state,deleteUccData:action.payload,notificationData:action.payload}

		case 'GOT_MANDATES_SUMMARY_DATA':
			return{...state,mandatesSummaryData:action.payload}
		case 'FAILED_MANDATES_SUMMARY_DATA':
			return{...state,mandatesSummaryData:action.payload}

		case 'GOT_VIDEO_KYC_DATA':
			return{...state,videoKycData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_VIDOE_KYC':
			return {...state,loaderVideoKyc:action.payload}
		case 'FAILED_VIDEO_KYC_DATA':
			return{...state,videoKycData:action.payload,notificationData:action.payload}

		case 'GOT_EDIT_BSE_PROFILE_DATA':
			return{...state,editUserUccData:action.payload}
		case 'COMPONENT_LOADER_EDIT_BSE_PROFILE':
			return{...state,loaderEditBseProfile:action.payload}
		case 'FAILED_EDIT_BSE_PROFILE_DATA':
			return{...state,editUserUccData:action.payload}

		case 'GOT_INVESTOR_NAME_DATA':
			return{...state,investorsData:action.payload}
		case 'FAILED_INVESTOR_NAME_DATA':
			return{...state,investorsData:action.payload}

		case 'GOT_UCC_MANDATES_LIST_DATA':
			return{...state,uccMandateList:action.payload}
		case 'FAILED_UCC_MANDATES_LIST_DATA':
			return{...state,uccMandateList:action.payload}

		case 'GOT_NACH_BANK_STATUS':
			return{...state,bankNACHStatus:action.payload}
		case 'FAILED_NACH_BANK_STATUS':
			return{...state,bankNACHStatus:action.payload}

		case 'GOT_APPROVE_MANDATE_DATA':
			return{...state,approveMandateData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_APPROVE_MANDATE':
			return{...state,loaderApproveMandate:action.payload}
		case 'FAILED_APPROVE_MANDATE_DATA':
			return{...state,approveMandateData:action.payload,notificationData:action.payload}

		case 'GOT_PHYSICAL_MANDATE_DATA':
			return{...state,physicalMandateData:action.payload,notificationData:action.payload}
		case 'COMPONENT_LOADER_PHYSICAL_MANDATE':
			return{...state,loaderphysicalMandate:action.payload}
		case 'FAILED_PHYSICAL_MANDATE_DATA':
			return{...state,physicalMandateData:action.payload,notificationData:action.payload}

		case 'PREFILLED_BANK_DETAILS_DATA':
			return{...state,prefilledBankDetails:action.payload}

		case 'SUBMIT_PHYSICAL_MANDATE_PARAMS':
			return{...state,physicalMandateParams:action.payload}

		case 'FOLIO_UNDER_BROKER_DATA':
			return{...state,folioUnderBrokerData:action.payload}
		case 'FOLIO_UNDER_BROKER_LOADER':
			return{...state,folioUnderBrokerLoader:action.payload}
		case 'FAILED_FOLIO_UNDER_BROKER_DATA':
			return{...state,folioUnderBrokerData:action.payload}

		case 'REJECTED_UCC_DATA':
			return{...state,rejectedUccData:action.payload}
		case 'REJECTED_UCC_LOADER':
			return{...state,rejectedUCCLoader:action.payload}
		case 'FAILED_REJECTED_UCC_DATA':
			return{...state,rejectedUccData:action.payload}

		case 'SUCCESS_MAP_FOLIO_UCC':
			return{...state,mapFoliosToUCCData:action.payload}
		case 'MAP_FOLIO_UCC_LOADER':
			return{...state,mapFoliosToUCCLoader:action.payload}
		case 'FAILED_MAP_FOLIO_UCC':
			return{...state,mapFoliosToUCCData:action.payload}

		case 'SUCCESS_CREATE_CLIENT':
			return{...state,createClientData:action.payload}
		case 'FAILED_CREATE_CLIENT':
			return{...state,createClientData:action.payload}

		case 'GOT_UPLOAD_PHYSICAL_MANDATE_DATA':
			return{...state,gotPhysicalUploadData:action.payload}
		case 'COMPONENT_LOADER_UPLOAD_PHYSICAL_MANDATE':
			return{...state,loaderPhysicalUpload:action.payload}
		case 'FAILED_UPLOAD_PHYSICAL_MANDATE_DATA':
			return{...state,gotPhysicalUploadData:action.payload}

		case 'GOT_UPLOAD_DOC_FORM_DATA':
			return{...state,gotUploadDocFormData:action.payload}
		case 'COMPONENT_LOADER_UPLOAD_DOC_FORM':
			return{...state,loaderDocFormUpload:action.payload}
		case 'FAILED_UPLOAD_DOC_FORM_DATA':
			return{...state,gotUploadDocFormData:action.payload}

		case 'SHOW_HIDE_NOTIFICATION':
			return{...state,notificationData:action.payload}

		case 'EXTRACT_SIGNATURE_SUCCESSFUL':
			return { ...state, extractSignatureData: action.payload } 
		case 'FAILED_TO_EXTRACT_SIGNATURE':
			return { ...state, extractSignatureData: action.payload } 
		case 'COMPONENT_LOADER_EXTRACT_SIGNATURE' : 
			return { ...state, loaderExtractSignature: action.payload }
		case 'CANCELLED_CHEQUE_UPLOAD':
			return { ...state, isCancelledChequeUpload: action.payload }

		case 'COMPONENT_LOADER_PROFILE_LIST': 
			return{...state, loaderProfileList:action.payload}

		case 'GOT_BSE_LOGIN_URL':
			return { ...state, bseLoginURL: action.payload } 
		case 'FAILED_BSE_LOGIN_URL':
			return { ...state, bseLoginURL: action.payload } 

		case 'COMPONENT_LOADER_VIDOE_KYC_LINK':
			return {...state,loaderVideoKyc:action.payload}
		
		case 'NOMINEES_DETAILS_DATA_BSE':
			return {...state,savedNomineeDetailsData:action.payload}

		case 'PREFILED_NOMINEES_DETAILS_DATA_BSE':
			return {...state,prefilledNomineeDetails:action.payload}

		case 'EDIT_NOMINEES_DETAILS_DATA_BSE':
			return {...state,editNomineeDetailsData:action.payload}

		case 'GOT_BULK_UPLOAD_BSE_SIP_DATA':
			return {...state,bulkUploadBseSipData:action.payload}
		case 'FAILED_BULK_UPLOAD_BSE_SIP_DATA':
			return {...state,bulkUploadBseSipData:action.payload}
		
		case 'GOT_BSE_SIP_TXNS_DATA':
			return {...state,bseSipTxnsData:action.payload}
		case 'FAILED_BSE_SIP_TXNS_DATA':
			return {...state,bseSipTxnsData:null}	
			
		case 'CEASE_BSE_SIP_SUCCESSFULLY':
			return{...state, notificationData : action.payload}
		case 'FAILED_CEASE_BSE_SIP':
			return{...state, notificationData : action.payload}
		case 'GOT_NOMINEE_AUTHENTICATION_DATA':
			return{...state, nominationAuthData : action.payload}
		case 'FAILED_NOMINEE_AUTHENTICATION_DATA':
			return{...state, nominationAuthData : action.payload}
		case 'COMPONENT_LOADER_FOR_NOMINEE_AUTHENTICATION':
			return{...state, nominationAuthLoader : action.payload}
		case 'SUCCESS_AUTH_NOMINEE':
			return{...state, authNomineeUpdatedInfo : action.payload}
		case 'FAILED_AUTH_NOMINEE':
			return{...state, authNomineeUpdatedInfo : action.payload}
		case 'UCC_HOLDERS_CALL':
			return {...state,getUCCHoldersCall:action.payload}
		case 'SUCESS_SUBMIT_UCC_EDIT':
			return {...state, submitEditUcc : action.payload}
		case 'FAILED_SUBMIT_UCC_EDIT':
			return {...state,submitEditUcc : action.payload }
		case 'COMPONENT_LOADER_FOR_BSE_AUTHENTICATION':
			return{...state, bseAuthenticationLoader : action.payload}
		case 'BSE_ORDER_AUTHENTICATION_NOTIFICATION':
			return{...state, bseAuthentication : action.payload}
		case 'PAUSE_BSE_SIP_SUCCESS' :
			return{...state, notificationData : action.payload}
		case 'FAILED_PAUSE_BSE_SIP':
			return{...state, notificationData : action.payload}
		case 'CHANGE_BSE_SIP_MANDATE_SUCCESS':
			return { ...state, notificationData: action.payload }
		case 'FAILED_CHANGE_BSE_SIP_MANDATE':
			return { ...state, notificationData: action.payload }
		case 'GOT_MANDATE_STATUS_MESSAGE':
		case 'FAILED_MANDATE_STATUS_MESSAGE':
			return { ...state, bseMandateStatus:action.payload,notificationData: action.payload }
		case 'SUCCESS_BSE_AUTH_OTP':
		case 'FAILED_TO_SEND_BSE_AUTH_OTP':
			return { ...state, bseAuthOTP: action.payload }
		case 'OTP_TOAST_NOTIFICATION':
			return { ...state, otpToast: action.payload }
		default:
      		new Error('No action type matched in Filter reducer')
	}
	return state;
}