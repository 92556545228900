import React, { Fragment, useEffect, useState } from 'react'
import {CAPTION_MESSAGE} from 'app/constants/shared/commonConst'

const RefreshTextMsg = (props) => {
    const [refreshTextMsg,setRefreshTextMsg]= useState(false)
    useEffect(()=>{
        const validRefreshMsg = props.loaderMessage && CAPTION_MESSAGE.some(obj=> obj.label == props.loaderMessage)
        let refreshTimeout
        if(validRefreshMsg || props.isCustomLoader){
            refreshTimeout = setTimeout(()=>{
             setRefreshTextMsg(true)
            },10000)
        }
        return ()=>{
            clearTimeout(refreshTimeout)
        }
    },[])
  return (
    <Fragment>{refreshTextMsg && <div class={`font15 ${props.customClass ? props.customClass : ''}`}>{props.customLoaderMsg || CAPTION_MESSAGE[8] && CAPTION_MESSAGE[8].label}</div>}</Fragment>
  )
}

export default RefreshTextMsg