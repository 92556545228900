import React, { Fragment } from 'react';
import ReactDOM from 'react-dom'
import LineLoader from './lineLoader'
import TableLoader from './tableLoader'
import Spinner from '../../kycOnBoarding/spinner';
import AnimationLoader from './animationLoader';
import PopupSpinner from 'app/uiCollection/shared/loaders/spinner';
import RefreshTextMsg from './refreshTextMsg';
export class Loader extends React.Component{
  constructor(props){
    super(props);
    this.state={}
    
  }
  selectLoader(loaderInfo){
    let loaderName = this.props.loaderType? this.props.loaderType : 'line'
    return (
      <div class={ `loaderContainer ${ loaderName == 'tableLoader' ? 'removeAbsoluteClass'  : null } ` }>     
        {loaderName == 'line'  && 
          <LineLoader
            loaderWidth = { this.props.loaderWidth }
            loaderHeight = { this.props.loaderHeight }
          />
        }
        {loaderName == 'tableLoader'  && 
          <TableLoader
            loaderColumns = { this.props.loaderColumns }
            loaderRows = { this.props.loaderRows }
            loaderPagination = { this.props.loaderPagination }
          />
        }
        {loaderName == 'spinner'  && 
          <Spinner
          customClass="loaderForContainer"
          />
        }
         {loaderName == 'popUpSpinner'  && 
            <PopupSpinner
              customClass={this.props.customClass}
            />
          }
        {
          loaderName == 'analysisLoader' &&
          <AnimationLoader
          loaderHeight={this.props.loaderHeight}
          loaderWidth = {this.props.loaderWidth}
          animationName = {this.props.animationName}
          isLoop = {this.props.isLoop}
          isAutoplay = {this.props.isAutoplay}
          />
        }
        {this.props.loaderMessage &&
          <Fragment>
            <p>{this.props.loaderMessage}</p>
            <RefreshTextMsg
              loaderMessage={this.props.loaderMessage}
              customLoaderMsg = {this.props.customLoaderMsg}
            />
          </Fragment>
        }
      {this.props.loaderInnerPopupMessage && <h3>{this.props.loaderInnerPopupMessage}</h3>}
    </div>)
  }

  render(){
    return(
    <div class={`loader loaderBox ${this.props.zIndexUpThreelevel? this.props.zIndexUpThreelevel : ''} ${this.props.loaderCustomClass ? this.props.loaderCustomClass : ''} `}>
        {this.selectLoader(this.props.loaderInfo)}
      </div>
    )
  }
}



export default Loader;