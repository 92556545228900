import React from 'react'
//import { Route, IndexRoute } from 'react-router'
import { Route,Switch,Link} from 'react-router-dom';
import AuthenticateUser from 'app/utils/Auth'
import AuthCheck from 'app/utils/AuthCheck'
import BrokerRoute from 'app/routes/broker'
import ClientRoutes from 'app/routes/clients'
import LoadableLoginCont from 'app/uiCollection/user/loadableLogin'
import LoadableForgotPasswordCont from 'app/uiCollection/user/loadableForgotPassword'
import LoadableResetPasswordCont from 'app/uiCollection/user/LoadableResetPassword'
import LoadableSignUpCont from 'app/uiCollection/user/LoadableSignUp'
import { USER_TYPE } from 'app/constants'
import SetPageTitle from 'app/utils/setPageTitle'
import Loadable from 'react-loadable'
import Loader from 'app/uiCollection/shared/loaders'
import { LOADER_WIDTH } from 'app/constants/shared/commonConst'
import * as commonConst from 'app/constants/shared/commonConst'
import Cookie from 'app/utils/Cookie'
import TxnsConfirmation from 'app/uiCollection/public/TxnsConfirmation'
import {connect} from 'react-redux'
import {withRouter} from 'react-router';
// import AdminInfoRoutes from 'app/routes/admin'

// withRouter used to provide history to wrapped components

const Loading = () => 
    <div>
        <Loader 
            loaderType = 'line'
            loaderWidth = { LOADER_WIDTH[3].width }
            loaderHeight = { LOADER_WIDTH[3].height }
            loaderMessage = {commonConst.CAPTION_MESSAGE[2].label}
        />
    </div>

const Calculator = Loadable({
  loader: () => import('app/uiCollection/calculator'),
  loading: Loading,
})

const InvestOnlineMain = Loadable({
  loader: () => import('app/uiCollection/InvestOnline'),
  loading: Loading,
})

const GraphCalculator = Loadable({
  loader: () => import('app/uiCollection/calculator/graphCalculator'),
  loading: Loading,
})

const Factsheet = Loadable({
  loader: () => import('app/uiCollection/public/factsheet'),
  loading: Loading,
})

const Signup = Loadable({
  loader: () => import('app/uiCollection/public/signup'),
  loading: Loading,
})

const OnBoarding = Loadable({
  loader: () => import('app/uiCollection/onBoarding'),
  loading: Loading,
})

const OnboardingMFU = Loadable({
  loader: () => import('app/uiCollection/onboardingMFU'),
  loading: Loading,
})

const OnboardingBSE = Loadable({
  loader: () => import('app/uiCollection/onboardingBSE'),
  loading: Loading,
})

const SubscribeUser = Loadable({
  loader: () => import('app/uiCollection/user/subscribe'),
  loading: Loading,
})

const Policy = Loadable({
  loader: () => import('app/uiCollection/policies'),
  loading: Loading,
})

const AppInstallSteps = Loadable({
  loader: () => import('app/uiCollection/appStore'),
  loading: Loading,
})

const RiskProfileMailer = Loadable({
  loader: () => import('app/uiCollection/public/riskProfileMailer'),
  loading: Loading,
})

const KycOnBoarding = Loadable({
  loader: () => import('app/uiCollection/kycOnBoarding'),
  loading: Loading
})
const ContactUs = Loadable({
  loader: () => import('app/uiCollection/user/contactUs'),
  loading: Loading
})

class Routes extends React.Component{
  

    render(){
        return(
        <div> 
            <AuthCheck>
                <Route path='/login' component={LoadableLoginCont} />
                <Route path='/signup' component={LoadableSignUpCont} />
                <Route path='/investOnlineMain/:investOnlineType' component={InvestOnlineMain} />
                <Route path='/calculator/:calculatorType' component={Calculator} />
                <Route path='/graphCalculator' component={GraphCalculator} />
                <Route path='/public/factsheet' component={Factsheet} />
                <Route path='/public/signup/:stepNo?' component={Signup} />
                <Route path='/public/riskProfileMailer' component={RiskProfileMailer} />
                <Route path='/kycOnBoarding/:stepNo?' component={KycOnBoarding} />
            </AuthCheck>
           
            <Route path='/forgotPassword' component={LoadableForgotPasswordCont} />
            <Route path='/resetPassword/:username/:token' component={LoadableResetPasswordCont} />
            <Route path='/mfuOrderAuth/:token' component={TxnsConfirmation} />
            <Route path='/subscribe' component={SubscribeUser} />
            <Route path='/policies/:policyType' component={Policy} />
            <Route path='/appStore' component={AppInstallSteps} />
            <Route path='/contactUs' component={ContactUs} />
           
            {Cookie.getCookie('c_ux') && <AuthenticateUser onChange={p => SetPageTitle(p)}>
                <BrokerRoute/>
                <ClientRoutes/>
                {/* <AdminInfoRoutes/> */}
                <Route path='/onBoarding/:stepNo?' component={OnBoarding} />
                <Route path='/onboardingMFU/:stepNo?' component={OnboardingMFU} />
                <Route path='/onboardingBSE/:stepNo?' component={OnboardingBSE} />
            </AuthenticateUser>}
        </div>
        )
    }

}

const mapStateToProp = (state) =>{
    return {
      getUserInfo: state.userReducer.user
    }
}

export default withRouter(connect(mapStateToProp)(Routes))


